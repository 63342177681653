import PropTypes from "prop-types";
import { Box, Container, Stack, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";

import {
  samsung,
  bolt,
  visma,
  aws,
  accenture,
  AT,
} from "../../assets/companies";

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: 'url("/assets/gradient-bg.svg")',
}));

export const Layout = (props) => {
  const { children } = props;

  return (
    <LayoutRoot>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* First Column */}
          <Grid
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            // sx={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   backgroundColor: "black"
            // }}
            sx={{
              ...(theme) => theme.palette.mode === 'neutral.900'
                ? {
                  backgroundColor: 'dark',
                  color: 'neutral.900',
                }
                : {
                  backgroundColor: 'neutral.900',
                  color: 'neutral.50',
                },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            
          >
            <Grid justifyContent={"center"} alignItems={"center"}>
              <Stack
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "100%" }}
                paddingX={"20vmin"}
              >
                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ width: "100wv", color: "white" }}
                >
                  <Typography variant="h5" color="white">
                    Welcome to Kento
                  </Typography>
                  <p style={{ color: "#6C737F" }}>
                    A professional kit that comes with ready-to-use MUI
                    components developed with one common goal in mind, help you
                    build faster & beautiful applications.
                  </p>
                  <p>Join 6,000+ forward-thinking companies:</p>

                  <Stack
                    display={"flex"}
                    flexDirection={{
                      xl: "row",
                      lg: "row",
                      md: "row",
                      sm: "row",
                      xs: "column",
                    }}
                    width={{ md: "10vmin", sm: "10vmin" }}
                    gap={3}
                  >
                    <img src={samsung} alt="samsung" />
                    <img src={visma} alt="" />
                    <img src={bolt} alt="bolt" />
                    <img src={aws} alt="aws" />
                    <img src={accenture} alt="accenture" />
                    <img src={AT} alt="" />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {/* Second Column */}
          <Grid
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            // sx={{
            //   display: "flex",
            //   backgroundColor: "white",
            //   justifyContent: "center", // add justifyContent property
            //   alignItems: "center",
            // }}
            sx={(theme) => theme.palette.mode === 'neutral.900'
            ? {
              backgroundColor: 'dark',
              color: 'neutral.900',
             
            }
            : {
              backgroundColor: 'white',
              color: 'neutral.50',
              
            }}
          >
            <Container
              sx={{
                py: {
                  xs: "60px",
                  md: "120px",
                },
              }}
            >
              {children}
            </Container>
          </Grid>
        </Grid>
      </Box>
    </LayoutRoot>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
