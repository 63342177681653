import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { Issuer } from 'src/utils/auth';
import axios from 'axios';

const loginPaths = {
  [Issuer.Amplify]: paths.auth.amplify.login,
  [Issuer.Auth0]: paths.auth.auth0.login,
  [Issuer.Firebase]: paths.auth.firebase.login,
  [Issuer.JWT]: paths.auth.jwt.login
};

export const AuthGuard = (props) => {
  const { children } = props;
  const router = useRouter();
  const { isAuthenticated, issuer } = useAuth();
  const [checked, setChecked] = useState(false);
  const userId = localStorage.getItem("userId");
  const check = useCallback(async () => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({ returnTo: window.location.href }).toString();

      try {
        const response = await axios.get(`https://kentoapp.herokuapp.com/login/${userId}`);

        if (response.status === 200) {
          setChecked(true);
        } else {
          const href = loginPaths[issuer] + `?${searchParams}`;
          router.replace(href);
        }
      } catch (err) {
        console.error(err);
        const href = loginPaths[issuer] + `?${searchParams}`;
        router.replace(href);
      }
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, issuer, router,userId]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
