import axios from "axios"
import toast from 'react-hot-toast';
import {useState,useEffect} from "react"

export const useMockedUser = () => {
  const [user, setUser] = useState({ name: '', email: '' });
  const userId = sessionStorage.getItem("userId");

  useEffect(()=>{
    const fetchUser = async() =>{
      try{
        const response = await axios.get(`https://kento.herokuapp.com/login/${userId}`);
        const data = response.data;
        console.log(data)
        setUser({ name: data.name, email: data.email });  
        toast.success("Working!")
      }
      catch(err){
        toast.error("Something went wrong!")
      }
    }

    if(userId){
      fetchUser();
    }
  },[userId])

  return {
    avatar: '/assets/avatars/avatar-anika-visser.png',
    name: user.name,
    email: user.email
  };
};
